<template>
  <el-card class="box-card full-screen">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item :label="$t('tradeAssistant')">
          <el-select v-model="queryForm.loginName" :placeholder="$t('all')" filterable size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option v-for="(t,i) in orgList" :key="i" :label="t.name+' '+t.surname+' -- '+t.loginName" :value="t.loginName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('amount')">
          <el-input v-model="queryForm.amount" size="small" />
        </el-form-item>
        <el-form-item :label="$t('checkStatus')">
          <el-select v-model="queryForm.status" :placeholder="$t('all')" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('checkPending')" value="2"></el-option>
            <el-option :label="$t('consentHandIn')" value="3"></el-option>
            <el-option :label="$t('refuseHandIn')" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isLoading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="index" align="center" fixed="left" width="60"></el-table-column>
        <el-table-column prop="name" :label="$t('tradeAssistant')" min-width="160">
          <template slot-scope="scope">{{scope.row.name}} {{scope.row.surname}} ({{scope.row.loginName||''}})</template>
        </el-table-column>
        <el-table-column prop="createTime" :label="$t('time')" min-width="160">
          <template slot-scope="scope">
            {{$manba(scope.row.createTime).format('ymdhms')}}
          </template>
        </el-table-column>
        <el-table-column prop="amount" :label="$t('amount')" min-width="160">
          <template slot-scope="scope">
            <span class="franc">₣</span> {{scope.row.amount.formatMoney(0)}}
          </template>
        </el-table-column>
        <el-table-column prop="applyRemark" :label="$t('applayRemark')" min-width="160"></el-table-column>
        <el-table-column prop="status" :label="$t('status')" min-width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.status==2" class="text-danger">{{$t('checkPending')}}</span>
            <span v-if="scope.row.status==3" class="text-success">{{$t('consentHandIn')}}</span>
            <span v-if="scope.row.status==4" class="text-warning">{{$t('refuseHandIn')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="checkRemark" :label="$t('checkRemark')" min-width="160"></el-table-column>
        <el-table-column fixed="right" :label="$t('extend')" width="320" v-if="loginInfo.orgType!=1">
          <template slot-scope="scope">
            <div v-if="scope.row.status==2">
              <el-button size="mini" @click="updateStatus(scope.row,3)" type="primary">{{$t('consentHandIn')}}</el-button>
              <el-button size="mini" @click="updateStatus(scope.row,4)" type="warning">{{$t('refuseHandIn')}}</el-button>
            </div>
            <div v-if="scope.row.status!=2">
              <el-button size="mini" disabled type="primary">{{$t('consentHandIn')}}</el-button>
              <el-button size="mini" disabled type="warning">{{$t('refuseHandIn')}}</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />

    <el-drawer :title="$t('reasonsForRefusal')" :visible.sync="drawer" direction="rtl" :modal="false" :destroy-on-close="true" :wrapperClosable="false">
      <el-form :model="addForm" ref="addForm" :rules="rules">
        <el-input type="hidden" v-model="addForm.cashReceiveId"></el-input>
        <el-input type="hidden" v-model="addForm.status"></el-input>
        <el-form-item prop="remark">
          <el-input type="textarea" size="medium" v-model="addForm.remark" />
        </el-form-item>
        <div class="bottom-button">
          <el-button size="medium" type="primary" :loading="isLoading" @click="refuseHandIn('addForm')">{{$t('submit')}}</el-button>
        </div>
      </el-form>
    </el-drawer>
  </el-card>
</template>
<script>
import org from '@/api/org'
import card from '@/api/card'
export default {
  data () {
    return {
      queryForm: {},
      isLoading: false,
      queryTableHeight: String,
      //分页器默认参数
      pageSize: 20,
      currentPage: 1,
      drawer: false,
      addForm: {},
      tableData: {},

      orgList: []
    }
  },
  computed: {
    rules () {
      return { remark: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }] }
    },
    loginInfo () { return this.$store.state.globalParameter.loginInfo; }
  },
  methods: {
    getList () {
      let _this = this;
      card.pageCashReceive({
        param: { current: _this.currentPage, size: _this.pageSize, type: 1, ..._this.queryForm },
        success: res => {
          _this.tableData = res.data || {};
        }
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    updateStatus (row, operation) {
      if (operation == 3) {
        let _this = this;
        card.checkCashToHandIn({
          param: { cashReceiveId: row.id, status: operation },
          success: res => {
            if (res.code == 200) {
              _this.getList();
              _this.drawer = false;
              _this.$message.success(_this.$t('operateSuccessfully'));
            }
            else
              _this.$message.warning(_this.$t(res.msg));
          }
        })
      }
      else if (operation == 4) {
        this.drawer = true;
        this.addForm = { cashReceiveId: row.id, status: operation }
      }
    },
    refuseHandIn (refName) {
      let _this = this;
      _this.$refs[refName].validate(valid => {
        if (!valid) return false;
        card.checkCashToHandIn({
          param: { ..._this[refName] },
          success: res => {
            if (res.code == 200) {
              _this.getList();
              _this.drawer = false;
              _this.$message.success(_this.$t('operateSuccessfully'))
            }
            else
              _this.$message.warning(_this.$t(res.msg))
          }
        })

      })
    },
    getOrgUserPage () {
      let _this = this;
      org.getOrgUserPage({
        param: { current: 1, size: 10000, },
        success: res => {
          if (res.data)
            _this.orgList = res.data.records;
        }
      })
    }
  },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';
    this.getList();
    this.getOrgUserPage();
  }
}
</script>
<style lang="less" scoped>
</style>